import React from 'react';

class StageCardTile extends React.Component {
  render () {
    const size = this.props.size ?? "small";
    const type = this.props.type ?? "static";
    const stage = this.props.stage;
    const level = this.props.level;
    const status = this.props.status;

    const level_word = "lv";
    const key = "stage-" + stage + "-" + size;

    let class_name_postfix;

    if (size === "medium") {
      const class_name_status = "stage-static-medium-" + status;
      class_name_postfix = " " + class_name_status;
    }

    return type === "static"
      ? (
        <div className={"stage-" + type + "-" + size + " stage-static-choice" + class_name_postfix} key={key}>
          <div className={"stage-image stage-image-" + stage} key={key + '-i'}/>
          <div className={"stage-level"} key={key + '-lvl'}>{level}<span>{level_word}</span></div>
          <div className={"stage-status stage-status-" + status} key={key + '-st'}/>
        </div>
      )
      : (
        <div className={"stage-static-medium stage-flow-choice" + class_name_postfix} key={key}>
          <div className={"stage-image stage-image-" + stage} key={key + '-i'}/>
          <div className={"stage-level"} key={key + '-lvl'}>{level}<span>{level_word}</span></div>
          <div className={"stage-status stage-status-" + status} key={key + '-st'}/>
        </div>
      );
  }
}

export default StageCardTile;
