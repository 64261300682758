import React from 'react';
import StageCardTile from "../StageCardTile";
import ProgressBinding from "../../Progress/ProgressBinding";

class StageChoiceDesktopView extends React.Component {
  render () {
    const stages = this.props.stages;
    const onStage = this.props.onStage;
    const prompts = this.props.prompts;

    const tiles = [];

    for (let key in stages) {
      const stage = stages[key];

      const onResult = {
        "func": onStage,
        "params": stage["code"]
      }

      const message = stage["status"] !== "disabled"
        ? prompts.get("stage-" + stage.code)
        : prompts.get("empty");

      let tile = (
        <div className={"stage-choice-item"} key={stage.code + '_card'}>
          <StageCardTile
            type={"static"}
            size={"medium"}
            stage={stage.code}
            level={stage.level}
            status={stage.status}
          />
        </div>
      );

      tile = (
        <div>
          <ProgressBinding
            class={"stage-progress-static-bindings stage-choice-item-order" + key}
            type={"wide"}
            color={"choice"}
            content={tile}
            onResult={onResult}
            key={stage.code + '_progress'}
            disabled={stage["status"] === "disabled"}
          />
          <div className={"stage-choice-item-description-block-static stage-choice-item-description-block-static--order-" + key}>
            <div className={"stage-choice-item-description-block-static-content"}>
              {message}
            </div>
          </div>
        </div>
      )

      tiles.push(tile);
    }

    return tiles;
  }
}

export default StageChoiceDesktopView;