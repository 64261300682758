import React from 'react';
import {TransitionWrapper} from "../../TransitionWrapper";

class PortableResourcesTransition extends React.Component {
  render () {
    let result = [];
    let transition = this.props.transition ?? null;

    if (transition !== null) {
      let resources = this.props.resources ?? {};

      for (let key in resources) {
        let count = resources[key];

        let sign = "";

        if (count < 0) {
          count *= -1;
          sign = "-";
        } else {
          sign = "+";
        }

        result.push(
          <div className={"p-resource-flow-transition"} key={key}>
            <div className={"p-resource-flow-sign"}>{sign}</div>
            <div className={"p-resource-flow-count"}>{count}</div>
            <div className={"p-resource-flow-image"}>
              <img width="21" height="19" src={"/images/portable/icons/" + key + "-large.svg"} alt={key} title={key}/>
            </div>
          </div>
        );
      }

      const timeout = 400 + (200 * result.length);

      result = <div className={"p-transition-flow"}>
        <TransitionWrapper content={result} in_transition={transition} timeout={timeout} class_name={"p-transition-flow"}/>
      </div>
    } else {
      result = <div className={"p-transition-flow"}>
        <div className={"p-transition-flow-done"}/>
      </div>
    }

    return result;
  }
}

export default PortableResourcesTransition;
