import React from 'react';
import Codes from "../../../Engine/Codes";

class BuildersPortableCounter extends React.Component {
  render () {
    const name = this.props.name;
    const buildings_count = this.props.buildings;
    const upgrades_count = this.props.upgrades;
    const current = this.props.current;
    const onSwitch = this.props.onSwitch;

    let sector_counter_class;
    let counter;

    if (onSwitch !== null) {
      sector_counter_class = "p-sector-counter-builds-multiple";

      let sector_counter_builds_value_class = "p-sector-counter-builds-multiple-left-value";
      let sector_counter_upgrades_value_class = "p-sector-counter-builds-multiple-right-value";

      if (current === Codes.types.upgrade) {
        sector_counter_upgrades_value_class += " p-sector-counter-builds-multiple-right-selected";
      } else {
        sector_counter_builds_value_class += " p-sector-counter-builds-multiple-left-selected";
      }

      counter = (
        <div className={sector_counter_class}>
          <div className={sector_counter_builds_value_class}>
            {buildings_count}
          </div>
          <div className={sector_counter_upgrades_value_class}>
            {upgrades_count}
          </div>
        </div>
      );
    } else {
      counter = (
        <div className={"p-sector-counter-builds-single"}>
          {buildings_count}
        </div>
      );
    }

    return (
      <div className={"p-sector-counters"} onClick={onSwitch}>{counter}</div>
    );
  }
}

export default BuildersPortableCounter;
