import DefaultSector from "../DefaultSector";
import Codes from "../../../Engine/Codes";
import SkillsHandlers from "../../../Engine/Processing/SkillsHandlers";

class WoodNectar extends DefaultSector {
    parameters () {
        return {
            name: 'wood',
            type: Codes.types.collect,
            need_clicks: 12,
            resources: {},
            preview: {
                storage: 25,
            },
            result: {
                nectar: 1,
            }
        }
    }

    getNeedClicks () {
        return SkillsHandlers.handlerSeekerNeedClicks(this.app().player, super.getNeedClicks());
    }

    getResult () {
        let result = super.getResult();

        result = SkillsHandlers.handlerSeekerNectarCount(this.app().player, result);
        result = SkillsHandlers.handlerPromoterSpecialistsCount(this.app().player, result);

        return result;
    }

    getSwitcherClass () {
        return 'nectar';
    }
}

export default WoodNectar;