import React from 'react';
import PromptBinding from "../../PromptBinding";

class PortableSpecialProgressCounterView extends React.Component {
  render () {
    const chance = this.props.chance;
    const acceleration = this.props.acceleration;
    const is_visible = this.props.is_visible;
    const prompts = this.props.prompts;

    const prompt_key = 'resource-storage-prompt-key';
    const prompt_content = prompts.get('special');

    const content = (
      <div className={"p-queen-progress"} id={"storage-effect-id"}>
        <div className={"p-queen-progress-value"}>
          {acceleration}
          <span className={"p-queen-progress-percent"}>%</span>
        </div>
        <div className={"p-queen-progress-value"}>
          {chance}
          <span className={"p-queen-progress-percent"}>%</span>
        </div>
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          place={"top-end"}
        />
      : ""
  }
}

export default PortableSpecialProgressCounterView;