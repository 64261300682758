import React from 'react';
import StageCardTile from "./../StageCardTile";
import ProgressBinding from "../../Progress/ProgressBinding";
import BlocksSwitcher from "../../Switchers/BlocksSwitcher";

class StageChoicePortableView extends React.Component {
  render () {
    const stages = this.props.stages;
    const onStage = this.props.onStage;
    const prompts = this.props.prompts;

    const tiles = [];

    for (let key in stages) {
      const stage = stages[key];
      const is_disabled = stage["status"] === "disabled";

      let tile = (
        <div className={"stage-choice-item"} key={stage.code + '_card'}>
          <StageCardTile
            type={"flow"}
            size={"medium"}
            stage={stage.code}
            level={stage.level}
            status={stage.status}
          />
        </div>
      );

      const onResult = {
        "func": onStage,
        "params": stage["code"]
      }

      const message = stage["status"] !== "disabled"
        ? prompts.get("stage-" + stage.code)
        : prompts.get("empty");

      tile = (
        <div>
          <ProgressBinding
            class={"stage-progress-flow-bindings"}
            type={"wide"}
            flow={true}
            color={"choice"}
            content={tile}
            onResult={onResult}
            key={stage.code + '_progress'}
            disabled={is_disabled}
          />
          <div className={"stage-choice-item-description-block"}>
            <div className={"stage-choice-item-description-block-content"}>
              {message}
            </div>
          </div>
        </div>
      )

      tiles.push(tile);
    }

    return <BlocksSwitcher blocks={tiles}/>;
  }
}

export default StageChoicePortableView;