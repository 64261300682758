import React from "react";

class Practical {
  static number (number, fraction = 2) {
    return (number !== undefined)
      ? Number(Number(number).toFixed(fraction))
      : 0;
  }
  
  static percentage (max, current) {
    const value = (
      current > 0 &&
      current <= max
    )
      ? (current / max) * 100
      : 0;

    return Practical.number(value, 0);
  }

  static viewNumber (number, fraction = 2) {
    if (number !== undefined) {
      number = 0;
    }

    return Number(number).toFixed(fraction)
  }

  static mergeState(state, changes) {
    for (let key in changes) {
      state[key] = changes[key];
    }

    return state;
  }

  static requirementsAreMet (requirements, state) {
    let result = true;

    for (let type in requirements) {
      let value = requirements[type];

      if (! state.hasOwnProperty(type) || state[type] < value) {
        result = false;
        break;
      }
    }

    return result;
  }

  static filterList(object, closure) {
    const clone = {...object};

    for (let key in clone) {
      const item = clone[key];

      if (!closure(item, key)) {
        delete clone[key];
      }
    }

    return clone;
  }

  static concatList (...items) {
    let result = [];

    for (let n in items) {
      let item = items[n];

      if (!Array.isArray(item)) {
        item = [item];
      }

      result = result.concat(item);
    }

    return result;
  }

  static dump (data, level = 0) {
    let result = [];

    for (let key in data) {
      let value = data[key];

      let prefix = ''.padEnd(level, '-');

      if (typeof value === 'object') {
        result.push(<div>{key}</div>);
        result.push(Practical.dump(value, level + 1));
      } else {
        if (typeof value === 'number') {
          value = Practical.number(value, 4);
        }

        result.push(
          <div>{prefix} {key}: {value}</div>
        );
      }
    }

    return result;
  }
}

export default Practical;
