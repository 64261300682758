import React from 'react';
import PromptBinding from "../../PromptBinding";

class DesktopResourceCounterView extends React.Component {
  render () {
    const resource = this.props.resource;
    const count = this.props.count;
    const increase = this.props.increase;
    const is_visible = this.props.is_visible;

    const position = this.props.position;
    const prompts = this.props.prompts;

    const position_class = "resource-position-" + position;

    const prompt_key = 'resource-counter-block-' + resource;
    const prompt_content = prompts.get(resource);

    const content = (
      <div className={"resources-block"}>
        <div className={"resource-icon " + position_class}>
          <img src={"/images/icons/" + resource + "-large.svg"} alt={resource} title={resource}/>
        </div>
        <div className={"resource-count " + position_class}>
          {count}
        </div>
        <div className={"resource-increase " + position_class}>
          {increase}
          <span className={"resource-increase-measure"}>/s</span>
        </div>
      </div>
    );

    return is_visible
      ? <PromptBinding
          prompts={prompts}
          prompt_key={prompt_key}
          prompt_content={prompt_content}
          content={content}
          delay={1200}
        />
      : <div className={"resources-block"}>
          <div className={"resource-icon-hidden " + position_class}/>
          <div className={"resource-count-hidden " + position_class}/>
          <div className={"resource-increase-hidden " + position_class}/>
        </div>
  }
}

export default DesktopResourceCounterView;